.AppLoc {
    text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
    height: 180vh;
    margin: 0;
    flex-direction: column;
  }
  
  .searchTextarea {
    width: 40%;
    height: 80%;
    min-height: 500px;
    padding: 10px;
    margin: 20px auto;
    border: 1px solid #253221;
    background-color: #fff; /* Fundo branco */
    color: #333; /* Cor do texto */
    font-size: 16px;
    line-height: 1.8;
    text-align: left; /* Alinhamento do texto à esquerda */
    resize: none; /* Impede que o textarea seja redimensionado pelo usuário */
    font-family: 'Roboto', sans-serif; /* Uma fonte moderna e legível */
    transition: border-color 0.3s ease-in-out; /* Efeito de transição suave para a borda */
  }
  
  .searchTextarea:focus {
    outline: none; /* Remover a borda ao focar */
    border-color: #05d45f; /* Mudar a cor da borda quando focado */
  }
  
  .buttonloc {
    background-color: #07d934; /* Cor de fundo do botão */
    color: #fff; /* Cor do texto do botão */
    border: none;
    padding: 10px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease-in-out; /* Efeito de transição suave para a cor de fundo */
  }
  
  
 
  
  .searchResultsLoc {
    width: 80%;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
    text-align: left;
    max-height: 200px; /* Limitar a altura máxima para evitar que os resultados se espalhem muito */
    overflow-y: auto; /* Adicionar rolagem se a altura máxima for atingida */
  }
  /* Localizador.module.css */

