@media only screen and (max-width: 768px) {
    .divLogo2 {
      
      display: fixed;
      
    }
  
  }
  @media only screen and (min-width: 768px) {
    .divLogo2 {
      display: none;
      
    }
  
  }
  @media only screen and (max-width: 768px) {
    .divLogo11 {
        display: none;
      
      
    }
  
  }
  @media only screen and (min-width: 768px) {
    .divLogo11 {
        display: fixed;
        width: '80%';
        
    }
  
  }