.cela {

    margin-top: 40px;
    border-collapse: collapse;
    background-color: #3d3b3b;
    width: 70%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    overflow-x: auto;
    box-shadow: 1px 2px #88888860;
    text-align: center;
    color: #ebe4e4;
    vertical-align: middle;
    border: 1px solid #d6d1d160;

  }
.celaIsolada {

    margin-top: 40px;
    border-collapse: collapse;
    background-color: #ec1f1f;
    width: 70%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    overflow-x: auto;
    box-shadow: 1px 2px #88888860;
    text-align: center;
    color: #ebe4e4;
    vertical-align: middle;
    border: 1px solid #d6d1d160;

  }
  
  .cela th {
  
    text-align: center;
    background-color:  #141813;
    box-shadow: 1px  #88888817;
    justify-content: center;
    color: #808080;
    vertical-align: middle;
  
  }
  
  .cela td {
    
    text-align: center;
    flex-direction: row;
    margin-top: 100px;
    font-size: 15px;
    vertical-align: middle;
    background-color: rgb(206, 202, 202);
    color:  rgb(46, 44, 44);
  }
  
  
  @media only screen and (max-width: 768px) {
    .dataColumnTablet {
      display: none;
    }
  }

  @media only screen and (max-width: 768px) {
    .dataColumnTabletNome {
      font-weight: bold;
      width: 70%;
      text-align: left;
      color:#fbecec93;
    }
  }
  .iconHoverEffect:hover {
    filter: brightness(1.6);
    
  }