.footer {
    height:60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #eef5f7;
    margin-top: 20px;
  }

  @media print {
    .footer {
      display: none; /* Oculta o cabeçalho ao imprimir */
    }
  }