.home {

    display:"flex"
}

.containerA4Horizontal {
    width: 297mm; /* Largura da folha A4 em modo paisagem */
    height: 210mm; /* Altura da folha A4 em modo paisagem */
    margin: 0 auto; /* Centralizar a div na página */
    overflow: hidden; /* Para garantir que o conteúdo não vaze fora da div */
    /* Outros estilos que você deseja aplicar à div */
  }

  .listaGalerias {
    display: flex;
    justify-content: center;
    align-items: center; /* Centraliza verticalmente os itens */
    width: 100%;
    background-color: #a9a7a7;
    margin-top: 0;
    box-shadow: 3px 2px 8px #5e5d5d;
    height: 60px;
    font-size: 16px;
    font-weight: bold;
    font-family: 'policiapenal';

  }

  @media print {
    .listaGalerias {
      display: none;
    }
  }
  @media print {
    .pulolista {
      display: none;
    }
  }

  .pulolista{

    height: "40%";
    margin-top: 30;
  }