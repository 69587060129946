.home {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: -38px;
  font-family: 'policiapenal';

}

.cela {
  margin-top: 40px;
  border-collapse: collapse;
  background-color: rgb(214, 220, 221);
  width: 85%;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  overflow-x: auto;
  box-shadow: 5px 10px #8888889c;
  text-align: center;





}


.BarraTotal {
  display: flex;
  flex-direction: row;
  background-color: aliceblue;
  height: 80%;
  margin-bottom: 40px;


}


.BarraTotal p {
  display: inline-block;
  margin: 10px;
  padding-inline: 60px;

}

.quadroFora {
  align-items: center;
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: row;
  margin: 0.1em;
}





.myButton {
  vertical-align: top;
  box-shadow: inset 7px 0px 15px 5px #23395e;
  background: linear-gradient(to bottom, #19263c 5%, #484849 100%);
  background-color: #1c2941;
  border-radius: 17px;
  border: 2px solid #072d666c;
  display: inline-block;
  cursor: pointer;
  color: #a8c7f0;
  font-family: Arial;
  font-size: 16px;
  padding: 4px 11px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #263666;
  margin-top: 0.4em;
}

.myButton:hover {
  background: linear-gradient(to bottom, #415989 5%, #2e466e 100%);
  background-color: #415989;
}

.myButton:active {
  position: relative;
  top: 1px;
}

.divtabs {
 
  display: flex; /* Use flexbox para alinhar os itens */
  justify-content: center; /* Centralize os itens horizontalmente */
  align-items: center; /* Centralize os itens verticalmente */
  margin-bottom: 5%; /* Adicione margem inferior de 5% */
  background-color: #a9a7a7; /* Cor de fundo */
  text-align: center; /* Centralize o texto dentro dos elementos */
  box-shadow: 3px 2px 8px #5e5d5d; /* Adicione uma sombra */
  font-family: 'policiapenal'; /* Especifique a fonte */
  line-height: 2;

}

/* So that we can put other things behind */
.divtabs>div {
  display: inline
}

/* We want the buttons all on one line */
.divtabs>div>a {
  box-shadow: rgba(79, 76, 229, 0) 0px -2px 0px 0px;
  text-shadow: 0px 1px 0px #1951f827;
  border-radius: 25px;
  font-size: 1rem;
  letter-spacing: 0.06em;
  text-decoration: none;
  color: black;
  /* Looks more like a button than a link */
  padding: 12px;
  margin-bottom: 6px;
  /* Some breathing space */
  border-bottom: 0.1em solid rgba(204, 204, 204, 0.53);
  vertical-align: middle;
}

/* Visually connect tab and tab body */
.divtabs>div:not(:target)>a {
  color: #000205;

  border-bottom: none;
  /* Make the bottom border disappear */
}

/* Inactive tabs are dark gray */
.divtabs>div:target>a,
/* Apply to the targeted item or... */
:target #default2>a {
  /* ... to the default item */
  border-bottom: 0.1em solid #CCC;
  /* Visually connect tab and tab body */
  background: #CCC;
  font-weight: bolder;
}

/* Active tab is light gray */

.divtabs>div>div {
  background: #CCC;
  /* Light gray */
  z-index: -1;
  /* Behind, because the borders overlap */
  left: 0;
  top: 1.3em;
  /* The top needs some calculation... */
  bottom: 0;
  right: 0;
  /* Other sides flush with containing block */
  overflow: auto;
  /* Scroll bar if needed */
  padding: 0.3em;
  /* Looks better */
  border: 0.1em outset #BBB
}

/* 3D look */
.divtabs>div:not(:target)>div {
  /* Protect CSS1 & CSS2 browsers */
  position: absolute
}

/* All these DIVs overlap */
.divtabs>div:target>div,
:target #default2>div {
  position: absolute;
  /* All these DIVs overlap */
  z-index: -1
}

/* Raise it above the others */


.foraup {

  justify-content: center;
  height: 100%;
  display: flexbox;
  width: 800px;

}

.quadroGeral {
  display: flex;
  flex-wrap: nowrap;
  margin: 5px;
  padding: 10px;
 }

 .myButtonQG {
 box-shadow:inset 0px 1px 3px 0px #91b8b3;
 background-color:'#253221';
 border-radius:5px;
 border:1px solid #566963;
 display:inline-block;
 cursor:pointer;
 color:#ffffff;
 font-family:Arial;
 font-size:15px;
 font-weight:bold;
 padding:11px 23px;
 text-decoration:none;
 text-shadow:0px -1px 0px #2b665e;
   margin: 10px;
   margin-bottom: 45px;
}
.myButtonQG:hover {
 background:linear-gradient(to bottom, #6c7c7c 5%, #768d87 100%);
 background-color:#6c7c7c;
}
.myButtonQG:active {
 position:relative;
 top:1px;
}

@media only screen and (max-width: 768px) {
  .dataColumnTopo {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  .selectTablet {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .selectTablet {
    display: block;
    font-size: 30;
  }

}

@media only screen and (max-width: 768px) {
  .selectTablet2 {
  
    display: block;
    font-size: 30;
  }
}
@media only screen and (min-width: 768px) {
  .selectTablet2 {
    display: none;
  }

}

  

.containerSelect {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20;
}
