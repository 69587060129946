@font-face {
  font-family: 'policIapenal';
  src: url('../../src/assets/font/bentonsans-bold.otf') format('truetype');
}


.navbar {
    display: flex;
    box-shadow: rgba(79, 76, 229, 0.69) 0px -2px 10px 0px;
    justify-content: space-between;
    vertical-align: middle;
    align-items: center;
    padding: 1em 2em;
    background-color: #253221;
    color: #fff;
    letter-spacing: 1px;
   
  }
.navbarMobile {
    display: flex;
    box-shadow: rgba(79, 76, 229, 0.69) 0px -2px 10px 0px;
    justify-content: space-between;
    vertical-align: middle;
    align-items: center;
    padding: 1em 2em;
    background-color: #253221;
    color: #fff;
    letter-spacing: 1px;
  
  
   
  }
  @media print {
    .navbar {
      display: none; /* Oculta o cabeçalho ao imprimir */
    }
  }
  
  .brand {
    font-size: 1.4em;
    font-family: policiapenal;
  }
  
  .brand span {
    font-weight: 900;
    text-transform: uppercase;
  }
  
  .links_list {
    margin-top: 10px;
    vertical-align: middle;
    display: flex;
    list-style: none;
    
  }
  


  @media only screen and (max-width: 768px) {
    .links_list {
      display: none;
      
    }
  
  }
  @media only screen and (max-width: 768px) {
    .links_list2 {
      display: fixed;
      
    }
  
  }
  @media only screen and (min-width: 768px) {
    .links_list2 {
      display: none;
      
    }
  
  }
  @media only screen and (max-width: 768px) {
    .divLogo2 {
      
      display: fixed;
      align-items: center;
      justify-content: center;
      text-align: center;
      
    }
  
  }
  @media only screen and (min-width: 768px) {
    .divLogo2 {
      display: none;
      
    }
  
  }
  @media only screen and (max-width: 768px) {
    .divLogo {
      display: none;
     
      
    }
  
  }
  @media only screen and (min-width: 768px) {
    .divLogo {
    
      display: fixed;
    }
  
  }
  
  .links_list li {
    margin-right: 1em;
    
  }
  
  .links_list li a {
    padding: 0.4em 0.6em;
  }
  
  .active {
    background-color: #000;
    color: #fff;
  }

  .logo {
    width: '240px'; 
    height: 'auto';
    
  }


  
  .botaoHeader {
   font-family: policiapenal;
   letter-spacing: 1px;
  font-weight: lighter;
  }


  
  .centerContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }